import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const MainSlider = ({ banners }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };
  const navigate = useNavigate();
  // const { sportsData, oddsData, showBetSlip } = useSelector(
  //   (state) => state.sports
  // );
  const settings = {
    dots: false,
    fade: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // console.log("ticketsDataaaa:", ticketsData);
  // console.log("modalContent:", modalContent);
  console.log(banners);
  return (
    <div>
      <Slider {...settings} className="">
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            console.log(b);
            return (
              <div className="position-relative" key={index}>
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        {(b?.ctabtns.length &&
                          b.ctabtns.map((c, i) => {
                            return (
                              <Button
                                variant="primary"
                                className="button-primary d-none"
                                onClick={() => {
                                  window.location.href = c.actionLink;
                                }}
                              >
                                {c.label}
                              </Button>
                            );
                          })) ||
                          null}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  // href={
                  //   b.order === 1 ? "/sportsbook/cricket" : "/sportsbook/soccer"
                  // }
                  onClick={() => {
                    b.order === 1
                      ? navigate("/sports/Cricket", {})
                      : navigate("/sports/Cricket", {});
                  }}
                  className=""
                >
                  <img src={b.link} alt="First Slide" />
                </a>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default MainSlider;
