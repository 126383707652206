import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Button, Container, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";
import { IoEye } from "react-icons/io5";
import Footer from "../../../../containers/Footer";
import { getBonusList } from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { axiosService } from "../../../../utils/axiosService";
import LeftBarSports from "../../../components/LeftBarSports";
import { API_URLS } from "../../../../config/apiUrls";
import moment from "moment/moment";
import { useSWRFetcher } from "../../../../hooks/useSWRHooks";
const Bonus = ({ callType = "router" }) => {
  const isRouterCall = callType === "router";
  const dispatch = useDispatch();
  // const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();
  const { data } = useSWRFetcher({
    url: API_URLS.BONUS.CLAIMS,
    isExternalFetch: true,
    swrConfig: {
      refreshInterval: 30_000, // 30 sceond refresh
    },
  });

  //   {
  //     id: 1,
  //     name: "Full Name",
  //     code: "Rafce",
  //     amount: 1000,
  //     claimedAmount: 500,
  //     dateCreated: "01/06/23",
  //     icon: <IoEye />,
  //   },
  //   {
  //     id: 2,
  //     name: "Full Name",
  //     code: "Rafce",
  //     amount: 1000,
  //     claimedAmount: 500,
  //     dateCreated: "01/06/23",
  //     icon: <IoEye />,
  //   },
  //   {
  //     id: 3,
  //     name: "Full Name",
  //     code: "Rafce",
  //     amount: 1000,
  //     claimedAmount: 500,
  //     dateCreated: "01/06/23",
  //     icon: <IoEye />,
  //   },
  // ];

  return (
    <>
      {isRouterCall && <HeaderAfterLogin />}

      <main className="  main">
        {/* <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div> */}
        <div className="BonusPostSection">
          <div className="container">
            <div className="d-xl-none mt--14">{/* <LeftBarSports /> */}</div>
            <div className="">
              {isRouterCall && (
                <div className="heading">
                  <h4>Bonus</h4>
                  <div style={{ display: "flex" }}>
                    {/* <Button
                      onClick={() => navigate("/bonustc")}
                      style={{
                        margin: "0 10px 0 0",
                      }}
                      className="bonusButton"
                    >
                      Terms & Co.
                    </Button> */}
                    <div className="back-link" onClick={() => navigate(-1)}>
                      Back <BackIcon />
                    </div>
                  </div>
                </div>
              )}
              <div className=" bonusTable">
                <div className="table-responsive new_table ">
                  <Table className="referr_table">
                    <thead>
                      <tr>
                        {/* <th width="">Name</th> */}
                        <th width="">Code</th>
                        <th>Bonus Type</th>
                        <th width="">Bonus Amount</th>
                        <th width="">Rollover Amount </th>
                        {/* <th>Parts</th> */}
                        <th width="">Created date</th>
                        <th width="">View </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(data?.statement) &&
                        data?.statement.map((item, index) => (
                          <tr key={index}>
                            {/* <td>{item.bonusName}</td> */}
                            <td>{item.bonusCode}</td>
                            <td>{item.bonusType}</td>
                            <td>
                              {item.creditedAmount} / {item.creditableAmount}
                            </td>
                            <td>
                              {item.claimedAmount} / {item.goalAmount}
                            </td>
                            {/* <td>
                                {item.claimedParts} / {item.totalParts}
                              </td> */}
                            <td>
                              {" "}
                              {moment(item.createdAt).format("DD-MM-YY h:mm")}
                            </td>

                            <td>
                              {item.bonusType !== "referral_deposit" &&
                                item.bonusType !== "referral_signup" && (
                                  <Link to={`/bonus/${item.id}`}>
                                    <IoEye className="eye_" />
                                  </Link>
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          {isRouterCall && <Footer />}
        </div>
      </main>
    </>
  );
};

export default Bonus;
