import React, { useEffect } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import BackIcon from "../../../../assets/svg/BackIcon";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);

  const handleGatewayClick = (item) => {
    console.log("item", item);
    dispatch(setSelectedGateway(item));
    navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    navigate(`/deposit/bank_transfer`);
  };
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="WithdrawSection">
          <div className="container">
            <div className="gatewaySection">
              <div className="d-flex justify-content-between  heading">
                <h2 className="sectionTitle">Deposit</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  Back <BackIcon />
                </div>
              </div>
              <div className="gatewaypart mt-5">
                <Tabs
                  defaultActiveKey="Deposit"
                  id="uncontrolled-tab-example"
                  className=""
                >
                  <Tab eventKey="Deposit" title="Online Deposit">
                    {gatewaysList?.deposits
                      ?.sort((a, b) => a.order - b.order)
                      ?.filter((f) => f.type === "auto")
                      .map((item, index) => {
                        return (
                          <div
                            className="cards1"
                            onClick={() => {
                              handleGatewayClick(item);
                            }}
                            key={index}
                          >
                            <div className="card-icon">
                              <img src={DepositImg} />
                            </div>

                            <p>{item?.name}</p>
                          </div>
                        );
                      })}
                  </Tab>
                  <Tab eventKey="bank" title="Bank Transfer">
                    {bankDetails && (
                      <div
                        className="cards1"
                        onClick={() => {
                          handleBankTransferClick();
                        }}
                      >
                        {" "}
                        <div className="card-icon">
                          <img src={bankImg} style={{ width: "66px" }} />
                        </div>
                        <p>{"Bank Transfer"}</p>
                      </div>
                    )}

                    {gatewaysList?.deposits
                      ?.sort((a, b) => a.order - b.order)
                      ?.filter((f) => f.type === "manual")
                      .map((item, index) => {
                        return (
                          <div
                            className="cards1"
                            onClick={() => {
                              handleGatewayClick(item);
                            }}
                            key={index}
                          >
                            <div className="card-icon">
                              <img src={DepositImg} />
                            </div>
                            <p>{item?.name}</p>
                          </div>
                        );
                      })}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default GatewaysList;
