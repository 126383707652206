import React, { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";

import { axiosService } from "../../utils/axiosService";
import HeaderAfterLogin from "../../containers/HeaderAfterLogin";

// import LoadingImg from "../../../../assets/images/loader.gif";

const ExchangeLobbyPage = ({ showLayout = true }) => {
  const [casinoLobbyUrl, setCasinoLobbyUrl] = useState();

  useEffect(() => {
    console.log("useEffect");
    try {
      if (localStorage.getItem("token") !== null) {
        const result = axiosService.post(
          "https://jetfair.sportsinfo247.com/lobby/",
          {
            token: localStorage.getItem("token"),
            mobile: false,
          }
        );
        result
          .then((res) => {
            console.log(res);
            if (!res?.data?.error) {
              return setCasinoLobbyUrl(res.data.data.lobbyUrl);
            }
          })
          .catch((err) => {
            console.log(err);
            return;
          });
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }, []);

  return (
    <div className="live_casino_page live">
      {showLayout && (
        <div className="header-bg">
          <HeaderAfterLogin />
        </div>
      )}

      {!Boolean(casinoLobbyUrl) ? (
        <div className="custom_placeholder_parent">
          {/* <div className="custom_placeholder_child">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
          </div> */}
          <div className="loading_img">
            {/* <img src={LoadingImg} alt="loading" width={80} /> */}
          </div>
        </div>
      ) : casinoLobbyUrl === "notAllowed" ? (
        <Navigate to="/home" />
      ) : casinoLobbyUrl === "notFound" ? (
        <Navigate to="/home" />
      ) : (
        <div className="exchange_iframe">
          <div>
            <iframe
              title="Exchange"
              src={casinoLobbyUrl}
              frameBorder="0"
              style={{
                width: "100%",
                height: "100vh",
                maxHeight: "calc(100vh - 45px)",
                // aspectRatio: "16/9",
              }}
              // scrolling="no"
              allowFullScreen={true}
            >
              Your browser doesn't support iFrames.
            </iframe>
          </div>
        </div>
      )}
      {/*  Iframe End */}
    </div>
  );
};

export default ExchangeLobbyPage;
