import React, { useEffect } from "react";

const UseAppendScript = () => {
  useEffect(() => {
    return () => {
      const element = document.getElementById("fb-connect-id");
      if (element) {
        document.body.removeChild(element);
      }
    };
  }, []);
  return (payloadScript) => {
    const script = document.createElement("script");
    script.id = "fb-connect-id";
    const scriptText = document.createTextNode(payloadScript);

    script.appendChild(scriptText);
    document.body.appendChild(script);
  };
};

export default UseAppendScript;
