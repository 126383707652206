import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
// import { casinoTabs } from "../../lib/data";
// import { slotsData, casinoGames } from "../../lib/data/slots.data";
// import { GamesData } from "./GamesData";
// import { casinoTabs } from "../../lib/data";
// import { gamesData } from "../../lib/data/searchGamesData";
import { casinoTabs } from "../../lib/data";
import SearchIcon from "../../assets/images/icons/find.png";

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const [isSearched, setIsSearched] = useState(null);

  const totalGames = {
    ...casinoTabs.AllGames.slide1,
    ...casinoTabs.AllGames.slide2,
    ...casinoTabs.AllGames.slide3,
    ...casinoTabs.AllGames.slide4,
    ...casinoTabs.AllGames.slide5,
    ...casinoTabs.AllGames.slide6,
    ...casinoTabs.AllGames.slide7,
    ...casinoTabs.AllGames.slide8,
    ...casinoTabs.AllGames.slide9,
  };

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        return item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1;
      });
      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };

  return (
    <div>
      <div className="searchGames" onClick={handleShow}>
        <BiSearch />
        <p>Search Games</p>
      </div>
      {/* <div className="searchbar d-none d-md-block" onClick={handleShow}>
        <Form>
          <Form.Group className="position-relative">
            <Form.Control
              type="text"
              placeholder="Search your favourite games"
            />
            <span>
              <img src={SearchIcon} />
            </span>
          </Form.Group>
        </Form>
      </div> */}
      <div>
        <Modal show={show} onHide={handleClose} className="searchGames1">
          <Modal.Header closeButton>
            <Modal.Title>Search For Games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <InputGroup className="mb-3 search-icon">
              <Form.Control
                placeholder="Search"
                aria-label="Text input with dropdown button"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
                // style={{ color: "#000" }}
                autoFocus
              />
            </InputGroup>
            <div className="GamesShow">
              {filteredData &&
                filteredData.map((item) => (
                  <div className="displayGames">
                    <a href={item.redirectUrl}>
                      <img src={item.imgUrl} />
                    </a>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default GamesSearchModal;
