import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Modal, Nav, Navbar, Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import MenuIcon1 from "../assets/images/icons/menuicon.png";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/images/logoUpdated.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import EvolutionIcon from "../assets/images/games-icon/Evolution.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";
import Bonus from "../assets/images/bonus.png";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricket-icon.png";
import BotHeadIcon3 from "../assets/images/games-icon/football.png";
import BotHeadIcon4 from "../assets/images/games-icon/plane.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon.png";
import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/fortune-wheel.png";
import Referral from "../assets/images/refferal.png";
import Bonus1 from "../assets/images/bonus.png";

import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/supportgif.gif";
import BonusIcon from "../assets/images/bonus.png";
import SportsIcon1 from "../assets/images/Sports12.png";
import SearchIcon from "../assets/images/icons/find.png";
import WalletIcon1 from "../assets/images/icons/wallet.gif";

import ProfileIconNew from "../assets/images/icons/Profilewhite.png";
import MyBetsIcon from "../assets/images/icons/MyBetwhite.png";
import AccountStatementNew from "../assets/images/icons/AccountStatementwhite.png";

import BonusIcon1 from "../assets/images/bonus.png";
import AccountStatement1 from "../assets/images/icons/Account-setting.png";
import ReferralIcon1 from "../assets/images/icons/withdraw.png";
import MyBets1 from "../assets/images/icons/Account-statement.png";
import SignOut1 from "../assets/images/icons/signoutwhite.png";
import Home1 from "../assets/images/icons/home.png";
import ProfileIcon1 from "../assets/images/icons/Profile.png";
import { handleRightSideBar } from "../redux/app/actions";
import FetchingBal from "../assets/images/icons/fetch.gif";
import AmountIcon from "../assets/images/icons/rupee.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
// import { getExposureAmount } from "../redux/auth/actions";
import AffiliatesIcon from "../assets/images/icons/affiliate-marketing.png";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const { showRightSideBar } = useSelector((state) => state.app);
  const [showBalance, setShowBalanceLoading] = useState(false);
  window.onclick = (ev) => {
    if (document.getElementById("profiledropdown").contains(ev.target)) {
      // console.log("click in");
    } else {
      setDropShow(true);
      // handleSideBarVisibility(false);
    }
  };
  const [dropShow, setDropShow] = useState(true);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    handleSideBarVisibility(false);
    setShow(false);
  };
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 12,
    type: "inc",
  });

  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    dispatch(getExposureAmount());
    dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    let intervalID = setInterval(() => {
      setShowBalanceLoading((p) => !p);
    }, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const handleSideBarVisibility = (value) => {
    dispatch(handleRightSideBar(value));
  };
  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  const renderBalance = () => {
    return (
      <>
        <Dropdown className="headerMainDropdown" placement={"end"}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="cashloader"
          >
            <div className="balance-web">
              <ul>
                <li>
                  <img src={AmountIcon} alt="amouht balance" />
                  <span>
                    {numberWithCommas(
                      parseFloat(wallet?.cash) + parseFloat(wallet?.coins) || 0
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="deposit exposure_deposit"
          >
            Expo:
            <span className="">{numberWithCommas(exposureAmount || 0)}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-1">Cash</div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-1">
                    {numberWithCommas(wallet?.cash || 0)}
                  </div>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item href="">
              <div className="row">
                <div className="col-6">
                  <div className="balance ">Bonus</div>
                </div>
                <div className="col-6">
                  <div className="balance-amount">
                    {numberWithCommas(wallet?.coins) || 0}
                  </div>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };
  return (
    <header className="header aftrlgn">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="leftbar_toggle d-none ">
                  <Button
                    variant="primary"
                    onClick={() => setShowLeftbarToggle((s) => !s)}
                  >
                    <img src={MenuIcon1} />
                  </Button>
                </div>
                <div className="logo">
                  <a href="/home">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
              </div>

              <Navbar expand="xl" className="d-none ">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setShowToggle((s) => !s)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    <Nav.Link href="/home" className="logoImg d-xl-none">
                      <img src={appDetails?.LOGO_URL || logo} alt="Logo" />
                    </Nav.Link>
                    <Nav.Link href="/home">
                      <img src={HomeIConNew} />
                      Home
                    </Nav.Link>
                    <Nav.Link href="/sports" className="">
                      <div>
                        <img src={SportsIconNew} />
                      </div>
                      Exchange
                    </Nav.Link>
                    <Nav.Link href="/casino/spribe/aviator">
                      <div className="animate-img">
                        <div className="enter-animate active">
                          <div className="spin-anim">
                            <img src={casinoround} className="img-spinbg" />
                            <img src={casinogame} className="img-turntable" />
                            <img src={casinopoint} className="img-pointer" />
                          </div>
                        </div>
                      </div>
                      Casino
                    </Nav.Link>

                    <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                      <img src={EzugiIconNew} />
                      Ezugi
                    </Nav.Link>
                    <Nav.Link href={CasinoProviders["evolution"]?.href}>
                      <img src={EvolutionIconNew} />
                      Evolution
                    </Nav.Link>

                    <Nav.Link href="/cashier">
                      <img src={CashierIconNew} />
                      Cashier
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div className="headerRight">
                <div>
                  <GamesSearchModal />
                </div>
                {/* <a href="#" className="headicons">
                  <img src={AffiliatesIcon} className="texticon" />
                  Affiliates
                </a> */}
                <a href="/sports/Cricket" className="headicons">
                  <img src={SportsIcon1} /> Sports
                </a>
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  className="headicons support"
                >
                  <img src={WhatsappIcon1} /> Support
                </a>
                <a href="/bonus" className="headicons">
                  <img src={BonusIcon} /> Bonus
                </a>
                <a href="/wallet" className="headicons">
                  <img src={WalletIcon1} /> Wallet
                </a>

                {renderBalance()}
                <Dropdown align="end" className="me-2 d-none ">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="tel:{user?.mobileno}">
                      <b>{user?.mobileno}</b>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>Balance</h6>
                      <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => setShowBetModal(true)}
                    >
                      <h6>Expo.</h6>
                      <span>INR {numberWithCommas(exposureAmount || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>Bonus</h6>
                      <span>INR {numberWithCommas(wallet?.coins)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/gatewaylist" className="deposit">
                      Deposit
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div
                  className="user-drop"
                  id="profiledropdown"
                  onClick={() => {
                    setDropShow(!dropShow);
                  }}
                >
                  <div className="user-drop-wrp">
                    <div className="user-button">
                      <img src={ProfileIconNew} />
                    </div>
                    <div
                      className={`user-content-section ${dropShow && `d-none`}`}
                    >
                      <div className="user-content">
                        <ul>
                          <li>
                            <span>{user?.mstruserid}</span>
                            <a href="#">{user?.mobileno}</a>
                          </li>

                          <li>
                            <p className="m-0">
                              Balance
                              <span>{numberWithCommas(wallet?.cash || 0)}</span>
                            </p>
                          </li>
                        </ul>
                        <div className="user-other-option">
                          <div className="row offcanvas-head">
                            <div
                              className="col-6 "
                              onClick={() => {
                                navigate("/withdraw");
                              }}
                            >
                              <Button
                                variant="  w-100"
                                className="btn-secondary"
                              >
                                Withdraw
                              </Button>
                            </div>
                            <div
                              className="col-6"
                              onClick={() => {
                                navigate("/gatewaylist");
                              }}
                            >
                              <Button variant="primary w-100">Deposit</Button>
                            </div>
                          </div>

                          <ul>
                            <li>
                              <a href="/profile">
                                <img src={ProfileIconNew} />
                                Profile
                              </a>
                            </li>
                            <li>
                              <a href="/cashier">
                                {" "}
                                <img src={AccountStatementNew} />
                                Account Statement
                              </a>
                            </li>
                            <li>
                              <a href="/mybets">
                                <img src={MyBetsIcon} />
                                My Bets
                              </a>
                            </li>
                            <li>
                              <a href="/bonus">
                                {" "}
                                <img src={BonusIcon1} />
                                Bonus
                              </a>
                            </li>
                            <li>
                              <a href="/referral">
                                <img src={ReferralIcon1} />
                                Referral
                              </a>
                            </li>

                            <li onClick={() => dispatch(logoutUser())}>
                              <a href="/" className="signout">
                                <img src={SignOut1} />
                                Sign Out
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="funds d-none">
                  <ul>
                    <li className="d-none">
                      <a href="/gatewaylist" className="deposit">
                        Deposit
                      </a>
                    </li>
                    <Dropdown
                      className="headerMainDropdown d-none d-md-none"
                      placement={"end"}
                    >
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="deposit exposure_deposit"
                      >
                        Exposure:
                        <span className="">
                          {numberWithCommas(exposureAmount || 0)}
                        </span>
                      </Dropdown.Toggle>

                      {/* <Dropdown.Menu>
                        <Dropdown.Item href="">
                          <div className="row">
                            <div className="col-6">
                              <div className="balance mb-1">Main Balance</div>
                            </div>
                            <div className="col-6">
                              <div className="balance-amount mb-1">
                                {numberWithCommas(wallet?.cash || 0)}
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <div className="row">
                            <div className="col-6">
                              <div className="balance ">Bonus</div>
                            </div>
                            <div className="col-6">
                              <div className="balance-amount">
                                {numberWithCommas(wallet?.coins) || 0}
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="">
                          <div
                            className="col-12 "
                            onClick={() => {
                              navigate("/withdraw");
                            }}
                          >
                            <Button variant="  w-100" className="primary">
                              Withdraw
                            </Button>
                          </div>
                        </Dropdown.Item> 
                        <Dropdown.Item href="">
                          <div
                            className="col-12"
                            onClick={() => {
                              navigate("/gatewaylist");
                            }}
                          >
                            <Button variant="primary w-100">Deposit</Button>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu> */}
                    </Dropdown>
                  </ul>
                </div>

                <Button
                  variant="primary menu-btn "
                  className="d-none"
                  onClick={() => handleSideBarVisibility(true)}
                >
                  <img src={ProfileIconNew} />
                  {/* <MenuIcon /> */}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={showRightSideBar}
        onHide={handleClose}
        placement={"end"}
        className="menuDropdown"
        id="canvas-container"
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <img src={ProfileIconNew} />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row offcanvas-head">
            <div className="col-6">
              <div className="balance mb-1">Cash</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-1">
                {numberWithCommas(wallet?.cash || 0)}
              </div>
            </div>
            <div className="col-6">
              <div className="balance mb-3">Bonus</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                {numberWithCommas(wallet?.coins) || 0}
              </div>
            </div>
            <div
              className="col-6 "
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="  w-100" className="btn-secondary">
                Withdraw
              </Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/home">
                <img src={Home1} />

                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/profile">
                <img src={ProfileIconNew} />

                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <img src={MyBetsIcon} />
                <span>My Bets</span>
              </a>
            </li>
            {/* <li>
              <a href="/bonustc">
                <img src={BonusIcon1} />
                <span>Bonus</span>
              </a>
            </li> */}
            <li>
              <a href="/referral">
                <img src={ReferralIcon1} />
                <span>Referral</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <img src={AccountStatementNew} />
                <span>Account Statement</span>
              </a>
            </li>

            <li
              onClick={() => {
                dispatch(logoutUser());
              }}
            >
              <a href="/">
                {/* <LogoutIcon /> */}
                <img src={SignOut1} />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a
                href={
                  appDetails.TELEGRAM != ""
                    ? appDetails.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails.TELEGRAM != "" ? "_blank" : ""}
              >
                <TelegramIcon />
              </a>
            </li>
            {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <WhatsappIcon />
              </a>
            </li>
            <li className="theme-btn">
              <buton
                type="button"
                onClick={() => setShowLightModeToggle((s) => !s)}
              >
                <div className="theme-icon">
                  <img src={MoonLight} alt="Moon Icon" className="dark_theme" />
                  <img src={SunLight} alt="Sun Icon" className="light_theme" />
                </div>
              </buton>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <div className="bottom_head">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                <li>
                  <a href="/casino/ezugi">
                    <img
                      src={BotHeadIcon1}
                      alt="bottom head link icon"
                      className="inplay"
                    />
                    <span>Ezugi</span>
                  </a>
                </li>
                <li>
                  <a href="/Slots">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Slots</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution">
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Evolution</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/aviator">
                    <img src={BotHeadIcon4} alt="bottom head link icon" />
                    <span>Aviator</span>
                  </a>
                </li>
                <li>
                  <a href="/Spribe">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>Spribe</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/TeenPatti01">
                    <img src={BotHeadIcon6} alt="bottom head link icon" />
                    <span>Vivo</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default HeaderAfterLogin;
