import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import { slotsData } from "../../../../lib/data/slots.data";

import PlayBtn from "../../../../assets/images/play-btn.png";

import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

const Slots = ({ showLayout = true }) => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      {showLayout && (
        <div>
          <HeaderAfterLogin />
        </div>
      )}
      <main className="main">
        <div className="WithdrawSection">
          <div className="container-fluid">
            <div className="slots-section">
              <div className="games-section">
                <div className="heading">
                  <span>Slots</span>
                  <div className="back-link" onClick={() => navigate(-1)}>
                    Back <BackIcon />
                  </div>
                </div>
                <ul className="mt-3">
                  {Object.values(slotsData).map((item) => {
                    return (
                      <li className="game-section">
                        <a href={isAuth ? item.redirectUrl : "/login"}>
                          <img src={item.imgUrl} />
                          <div className="casino_overlay">
                            <img src={PlayBtn} />
                          </div>
                          <p>{item.name}</p>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      {showLayout && (
        <div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Slots;
