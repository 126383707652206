export const slotsData = {
  // gonzoquest: {
  //   redirectUrl: "/casino/ezgevo-gonzos-treasure-hunt",
  //   imgUrl: "./images/slots/SlotsImg2.jpg",
  //   name: "Gonzo Treasure Hunts",
  // },
  asgardianstones: {
    redirectUrl: "/casino/ezgne-asgardian-stones",
    imgUrl: "./images/slots/asgardianstones.jpg",
    name: "Asgardian Stones",
  },
  secrets: {
    redirectUrl: "/casino/ezgne-secrets-of-atlantis",
    imgUrl: "./images/slots/secrets-of-atlantis.jpg",
    name: "Secrets of Atlantis",
  },
  slots4: {
    redirectUrl: "/casino/ezgrt-grand-wheel",
    imgUrl: "./images/slots/grand-wheel.jpg",
    name: "Grand Wheel",
  },
  slots5: {
    redirectUrl: "/casino/ezgrt-777-strike",
    imgUrl: "./images/slots/777strike.jpg",
    name: "777 Strike",
  },
  slots6: {
    redirectUrl: "/casino/ezgrt-10001-nights",
    imgUrl: "./images/slots/10001-nights.jpg",
    name: "10001 Nights",
  },
  slots7: {
    redirectUrl: "/casino/ezgrt-cash-ultimate",
    imgUrl: "./images/slots/cash_ultimate.jpg",
    name: "Cash Ultimate",
  },
  slots8: {
    redirectUrl: "/casino/ezgrt-cash-volt",
    imgUrl: "./images/slots/cash_volt.jpg",
    name: "Cash Volt",
  },
  slots9: {
    redirectUrl: "/casino/ezgne-dazzle-me-megaways",
    imgUrl: "./images/slots/dazzle_me_megaways.jpg",
    name: "Dazzle Me Megaways",
  },
  slots10: {
    redirectUrl: "/casino/ezgne-divine-fortune-megaways",
    imgUrl: "./images/slots/divine_fortune_megaways.jpg",
    name: "Divine Fortune Megaways",
  },
  slots11: {
    redirectUrl: "/casino/ezgrt-dynamite-riches",
    imgUrl: "./images/slots/dynamite_riches.jpg",
    name: "Dynamite Riches",
  },
  slots12: {
    redirectUrl: "/casino/ezgrt-fortune-house",
    imgUrl: "./images/slots/fortunehouse.jpg",
    name: "Fotune House",
  },
  slots13: {
    redirectUrl: "/casino/ezgne-fruit-shop-megaways",
    imgUrl: "./images/slots/Fruit_Shop_Megaways.jpg",
    name: "Fruit Shop Megaways",
  },
  slots14: {
    redirectUrl: "/casino/ezgrt-golden-cryptex",
    imgUrl: "./images/slots/Golden_Cryptex.jpg",
    name: "Golden Cryptex",
  },
  slots15: {
    redirectUrl: "/casino/ezgrt-golden-tsar",
    imgUrl: "./images/slots/Golden_Tsar.jpg",
    name: "Golden Tsar",
  },
  slots16: {
    redirectUrl: "/casino/ezgne-gorilla-kingdom",
    imgUrl: "./images/slots/Gorilla_Kingdom.jpg",
    name: "Gorilla Kingdom",
  },
  slots17: {
    redirectUrl: "/casino/ezgrt-mystery-reels-megaways",
    imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
    name: "Mystery Reels Megaways",
  },
  slots18: {
    redirectUrl: "/casino/ezgne-rage-of-the-seas",
    imgUrl: "./images/slots/Rage_Of_The_Seas.jpg",
    name: "Rage Of The Seas",
  },
  slots19: {
    redirectUrl: "/casino/ezgrt-reel-king-mega",
    imgUrl: "./images/slots/Reel_King_Mega.jpg",
    name: "Reel King Mega",
  },
  slots20: {
    redirectUrl: "/casino/ezgne-turn-your-fortune",
    imgUrl: "./images/slots/Turn_Your_Fortune.jpg",
    name: "Turn Your Fortune",
  },
  slots21: {
    redirectUrl: "/casino/ezgne-twin-spin-megaways",
    imgUrl: "./images/slots/Twin_Spin_Megaways.jpg",
    name: "Twin Spin Megaways",
  },
  slots22: {
    redirectUrl: "/casino/ezgne-willys-hot-chillies",
    imgUrl: "./images/slots/Willys_Hot_Chillies.jpg",
    name: "Willys Hot Chillies",
  },
  slots23: {
    redirectUrl: "/casino/ezgrt-chinese-treasures",
    imgUrl: "./images/slots/Chinese-Treasures.jpg",
    name: "Chinese Treasures",
  },
  slots24: {
    redirectUrl: "/casino/ezgrt-dragons-luck",
    imgUrl: "./images/slots/Dragon's-Luck.jpg",
    name: "Dragon's Luck",
  },
  slots25: {
    redirectUrl: "/casino/ezgrt-dragons-fire",
    imgUrl: "./images/slots/Dragon's-Fire.jpg",
    name: "Dragon's Fire",
  },
  slots26: {
    redirectUrl: "/casino/ezgrt-dragons-luck-deluxe",
    imgUrl: "./images/slots/Dragon's-Luck-Deluxe.jpg",
    name: "Dragon's Luck Deluxe",
  },
  slots27: {
    redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
    imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.jpg",
    name: "Dragon's Luck Power Reels",
  },
  slots28: {
    redirectUrl: "/casino/ezgrt-epic-journey",
    imgUrl: "./images/slots/Epic-Journey.jpg",
    name: "Epic Journey",
  },
  slots29: {
    redirectUrl: "/casino/ezgrt-god-of-wealth",
    imgUrl: "./images/slots/God-Of-Wealth.jpg",
    name: "God Of Wealth",
  },
  slots30: {
    redirectUrl: "/casino/ezgrt-lucky-fortune-cat",
    imgUrl: "./images/slots/Lucky-Fortune-Cat.jpg",
    name: "Lucky Fortune Cat",
  },
  slots31: {
    redirectUrl: "/casino/ezgrt-magic-gate",
    imgUrl: "./images/slots/Magic-Gate.jpg",
    name: "Magic Gate",
  },
  slots32: {
    redirectUrl: "/casino/ezgrt-golden-temple",
    imgUrl: "./images/slots/Golden-Temple.jpg",
    name: "Golden Temple",
  },
  slots33: {
    redirectUrl: "/casino/ezgrt-phoenix-fire-power-reels",
    imgUrl: "./images/slots/Phoenix-Fire-Power-Reels.jpg",
    name: "Phoenix Fire Power Reels",
  },
  slots34: {
    redirectUrl: "/casino/ezgrt-golden-offer",
    imgUrl: "./images/slots/Golden-Offer.jpg",
    name: "Golden Offer",
  },
  slots35: {
    redirectUrl: "/casino/ezgrt-ras-legend",
    imgUrl: "./images/slots/RA's-Legend.jpg",
    name: "RA's Legend",
  },
  slots36: {
    redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
    imgUrl: "./images/slots/Gonzo's-Quest-Megaways.jpg",
    name: "Gonzo's Quest Megaways",
  },
  slots37: {
    redirectUrl: "/casino/ezgrt-rainbow-jackpots",
    imgUrl: "./images/slots/Rainbow-Jackpots.jpg",
    name: "Rainbow Jackpots",
  },
  slots38: {
    redirectUrl: "/casino/ezgrt-mega-pyramid",
    imgUrl: "./images/slots/Mega-Pyramid.jpg",
    name: "Mega Pyramid",
  },
  slots39: {
    redirectUrl: "/casino/ezg-jimihendrixonlineslot",
    imgUrl: "./images/slots/Jimi_Hendrix.jpg",
    name: "Jimi Hendrix",
  },
  slots40: {
    redirectUrl: "/casino/ezg-jumanji",
    imgUrl: "./images/slots/Jumanji.jpg",
    name: "Jumanji",
  },
  slots41: {
    redirectUrl: "/casino/ezg-motorheadvideoslot",
    imgUrl: "./images/slots/Motorhead_Video_Slot.jpg",
    name: "Motorhead Video Slot",
  },
  slots42: {
    redirectUrl: "/casino/ezg-starburst",
    imgUrl: "./images/slots/Starburst.jpg",
    name: "Starburst",
  },
  slots43: {
    redirectUrl: "/casino/ezgrt-war-of-gods",
    imgUrl: "./images/slots/War-Of-Gods.jpg",
    name: "War Of Gods",
  },
  slots44: {
    redirectUrl: "/casino/ezgne-aloha-christmas",
    imgUrl: "./images/slots/Aloha-Christmas.jpg",
    name: "Aloha Christmas",
  },
  slots45: {
    redirectUrl: "/casino/ezgne-aloha-cluster-pays",
    imgUrl: "./images/slots/Aloha-Cluster-Pays.jpg",
    name: "Aloha Cluster Pays",
  },
  slots46: {
    redirectUrl: "/casino/ezgrt-ancients-blessing",
    imgUrl: "./images/slots/Ancients-Blessing.jpg",
    name: "Ancients Blessing",
  },
  slots47: {
    redirectUrl: "/casino/ezgrt-aurum-codex",
    imgUrl: "./images/slots/Aurum-Codex.jpg",
    name: "Aurum Codex",
  },
  slots48: {
    redirectUrl: "/casino/qt-guns-n-roses-video-slots",
    imgUrl: "./images/slots/Guns_N_Roses.jpg",
    name: "Guns N Roses",
  },
  slots49: {
    redirectUrl: "/casino/qt-betty-boris-and-boo",
    imgUrl: "./images/slots/Betty-Boris-and-Boo.jpg",
    name: "Betty Boris and Boo",
  },
  slots50: {
    redirectUrl: "/casino/ezgne-wild-wild-west-the-great-train-heist",
    imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.jpg",
    name: "Wild West The Great Train Heist",
  },
  slots51: {
    redirectUrl: "/casino/ezgne-steam-tower",
    imgUrl: "./images/slots/Steam-Tower.jpg",
    name: "Steam Tower",
  },
  slots52: {
    redirectUrl: "/casino/ezgrt-zeus-lightning-power-reels",
    imgUrl: "./images/slots/Zeus-Lightning-Power-Reels.jpg",
    name: "Zeus Lightning Power Reels",
  },
  slots53: {
    redirectUrl: "/casino/ezgrt-wings-of-ra",
    imgUrl: "./images/slots/Wings-of-Ra.jpg",
    name: "Wings of Ra",
  },
  slots54: {
    redirectUrl: "/casino/ezg-trillionaire",
    imgUrl: "./images/slots/Trillionaire.jpg",
    name: "Trillionaire",
  },
  slots55: {
    redirectUrl: "/casino/ezgrt-treasure-mine",
    imgUrl: "./images/slots/Treasure-Mine.jpg",
    name: "Treasure Mine",
  },
  slots56: {
    redirectUrl: "/casino/ezgrt-thors-vengeance",
    imgUrl: "./images/slots/Thor's-Vengeance.jpg",
    name: "Thor's Vengeance",
  },
  slots57: {
    redirectUrl: "/casino/ezgrt-regal-streak",
    imgUrl: "./images/slots/Regal-Streak.jpg",
    name: "Regal Streak",
  },
  slots58: {
    redirectUrl: "/casino/ezgrt-regal-beasts",
    imgUrl: "./images/slots/Regal-Beasts.jpg",
    name: "Regal Beasts",
  },
  slots59: {
    redirectUrl: "/casino/ezgrt-reel-keeper",
    imgUrl: "./images/slots/Reel-Keeper.jpg",
    name: "Reel Keeper",
  },
  slots60: {
    redirectUrl: "/casino/ezgrt-rainbow-jackpots-power-lines",
    imgUrl: "./images/slots/Rainbow-Jackpots-Power-Lines.jpg",
    name: "Rainbow Jackpots Power Lines",
  },
  slots61: {
    redirectUrl: "/casino/ezgrt-piggy-riches-megaways",
    imgUrl: "./images/slots/Piggy-Riches-Megaways.jpg",
    name: "Piggy Riches Megaways",
  },
  slots62: {
    redirectUrl: "/casino/ezgrt-jingle-bells",
    imgUrl: "./images/slots/Jingle-Bells.jpg",
    name: "Jingle Bells",
  },
  slots63: {
    redirectUrl: "/casino/ezgrt-jack-in-a-pot",
    imgUrl: "./images/slots/Jack-in-a-Pot.jpg",
    name: "Jack in a Pot",
  },
  slots64: {
    redirectUrl: "/casino/ezgrt-five-star",
    imgUrl: "./images/slots/Five-Star.jpg",
    name: "Five Star",
  },
  slots65: {
    redirectUrl: "/casino/ezgrt-bounty-raid",
    imgUrl: "./images/slots/Bounty-Raid.jpg",
    name: "Bounty Raid",
  },
  slots66: {
    redirectUrl: "/casino/ezgrt-mystery-reels",
    imgUrl: "./images/slots/Mystery-Reels.jpg",
    name: "Mystery Reels",
  },
  slots67: {
    redirectUrl: "/casino/ezgrt-diamond-blitz",
    imgUrl: "./images/slots/Diamond-Blitz.jpg",
    name: "Diamond Blitz",
  },
  slots68: {
    redirectUrl: "/casino/ezgrt-dragons-fire-megaways",
    imgUrl: "./images/slots/Dragon's-Fire-Megaways.jpg",
    name: "Dragon's Fire Megaways",
  },
  slots69: {
    redirectUrl: "/casino/ezgne-rome-the-golden-age",
    imgUrl: "./images/slots/Rome-The-Golden-Age.jpg",
    name: "Rome-The Golden Age",
  },
  slots70: {
    redirectUrl: "/casino/ezgne-reel-rush",
    imgUrl: "./images/slots/Reel-Rush.jpg",
    name: "Reel Rush",
  },
  slots71: {
    redirectUrl: "/casino/ezgne-blood-suckers",
    imgUrl: "./images/slots/Blood-Suckers.jpg",
    name: "Blood Suckers",
  },
  slots72: {
    redirectUrl: "/casino/ezgne-blood-suckers-2",
    imgUrl: "./images/slots/Blood-Suckers-2.jpg",
    name: "Blood Suckers 2",
  },
  slots73: {
    redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
    imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.jpg",
    name: "Pyramid Quest for Immortality",
  },
  slots74: {
    redirectUrl: "/casino/ezgne-butterfly-staxx",
    imgUrl: "./images/slots/Butterfly-Staxx.jpg",
    name: "Butterfly Staxx",
  },
  slots75: {
    redirectUrl: "/casino/ezgne-butterfly-staxx-2",
    imgUrl: "./images/slots/Butterfly-Staxx-2.jpg",
    name: "Butterfly Staxx 2",
  },
  slots76: {
    redirectUrl: "/casino/ezgne-piggy-riches",
    imgUrl: "./images/slots/Piggy-Riches.jpg",
    name: "Piggy Riches",
  },
  slots77: {
    redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
    imgUrl: "./images/slots/Dark-King-Forbidden-Riches.jpg",
    name: "Dark King: Forbidden Riches",
  },
  slots78: {
    redirectUrl: "/casino/ezgrt-jingle-bells-power-reels",
    imgUrl: "./images/slots/Jingle-Bells-Power-Reels.jpg",
    name: "Jingle Bells Power Reels",
  },
  slots79: {
    redirectUrl: "/casino/ezgne-dazzle-me",
    imgUrl: "./images/slots/Dazzle-Me.jpg",
    name: "Dazzle Me",
  },
  slots80: {
    redirectUrl: "/casino/ezgne-jack-hammer",
    imgUrl: "./images/slots/Jack-Hammer.jpg",
    name: "Jack Hammer",
  },
  slots81: {
    redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
    imgUrl: "./images/slots/Jack-Hammer-2-Fishy-Business.jpg",
    name: "Jack Hammer 2: Fishy Business",
  },
  slots82: {
    redirectUrl: "/casino/ezgne-dead-or-alive",
    imgUrl: "./images/slots/Dead-or-Alive.jpg",
    name: "Dead or Alive",
  },
  slots83: {
    redirectUrl: "/casino/ezgne-dead-or-alive-2",
    imgUrl: "./images/slots/Dead-or-Alive-2.jpg",
    name: "Dead or Alive 2",
  },
  slots84: {
    redirectUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
    imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.jpg",
    name: "Dead or Alive 2 Feature Buy",
  },
  slots85: {
    redirectUrl: "/casino/ezgne-jack-and-the-beanstalk",
    imgUrl: "./images/slots/Jack-and-the-Beanstalk.jpg",
    name: "Jack and the Beanstalk",
  },
  slots86: {
    redirectUrl: "/casino/ezgne-jingle-spin",
    imgUrl: "./images/slots/Jingle-Spin.jpg",
    name: "Jingle Spin",
  },
  slots87: {
    redirectUrl: "/casino/qt-gordon-ramsay-hells-kitchen",
    imgUrl: "./images/slots/Gordon_Ramsay_Hells_Kitchen.jpg",
    name: "Gordon Ramsay Hell’s Kitchen",
  },
  slots88: {
    redirectUrl: "/casino/ezgne-elements-the-awakening",
    imgUrl: "./images/slots/Elements-The-Awakening.jpg",
    name: "Elements: The Awakening",
  },
  // slots89: {
  //   redirectUrl: "/casino/ezugi/GonzosQuest",
  //   imgUrl: "./images/slots/Gonzos_Quest.jpg",
  //   name: "Gonzos Quest",
  // },
  slots90: {
    redirectUrl: "/casino/ezgne-finn-and-the-swirly-spin",
    imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.jpg",
    name: "Finn and the Swirly Spin",
  },
  slots91: {
    redirectUrl: "/casino/ezgne-fruit-shop",
    imgUrl: "./images/slots/Fruit-Shop.jpg",
    name: "Fruit Shop",
  },
  slots92: {
    redirectUrl: "/casino/ezgne-dazzle-me",
    imgUrl: "./images/slots/Dazzle-Me.jpg",
    name: "Dazzle Me",
  },
  slots93: {
    redirectUrl: "/casino/ezgne-double-stacks",
    imgUrl: "./images/slots/Double-Stacks.jpg",
    name: "Double Stacks",
  },
  slots94: {
    redirectUrl: "/casino/ezgne-fairytale-legends-red-riding-hood",
    imgUrl: "./images/slots/Fairytale-Legends-Red-Riding-Hood.jpg",
    name: "Fairytale Legends: Red Riding Hood",
  },
  slots95: {
    redirectUrl: "/casino/ezgne-flowers",
    imgUrl: "./images/slots/Flowers.jpg",
    name: "Flowers",
  },
  slots96: {
    redirectUrl: "/casino/ezgne-fruit-shop-christmas-edition",
    imgUrl: "./images/slots/Fruit-Shop-Christmas-Edition.jpg",
    name: "Fruit Shop Christmas Edition",
  },
  slots97: {
    redirectUrl: "/casino/ezgne-hotline",
    imgUrl: "./images/slots/Hotline.jpg",
    name: "Hotline",
  },
  slots98: {
    redirectUrl: "/casino/ezgne-hotline-2",
    imgUrl: "./images/slots/Hotline-2.jpg",
    name: "Hotline 2",
  },
  // slots99: {
  //   redirectUrl: "/casino/qt-the-invisible-man",
  //   imgUrl: "./images/slots/The_Invisible_Man.jpg",
  //   name: "The Invisible Man",
  // },
  slots100: {
    redirectUrl: "/casino/ezgne-irish-pot-luck",
    imgUrl: "./images/slots/Irish-Pot-Luck.jpg",
    name: "Irish Pot Luck",
  },
  slots101: {
    redirectUrl: "/casino/ezgne-fruit-spin",
    imgUrl: "./images/slots/Fruit-Spin.jpg",
    name: "Fruit Spin",
  },
  slots102: {
    redirectUrl: "/casino/ezgne-jungle-spirit-call-of-the-wild",
    imgUrl: "./images/slots/Jungle-Spirit-Call-of-the-Wild.jpg",
    name: "Jungle Spirit: Call of the Wild",
  },
  slots103: {
    redirectUrl: "/casino/ezgne-koi-princess",
    imgUrl: "./images/slots/Koi-Princess.jpg",
    name: "Koi Princess",
  },
  slots104: {
    redirectUrl: "/casino/ezgne-drive-multiplier-mayhem",
    imgUrl: "./images/slots/Drive-Multiplier-Mayhem.jpg",
    name: "Drive Multiplier Mayhem",
  },
  slots105: {
    redirectUrl: "/casino/ezgne-rage-of-the-seas",
    imgUrl: "./images/slots/Rage-of-the-Seas.jpg",
    name: "Rage of the Seas",
  },
  slots106: {
    redirectUrl: "/casino/ezgne-riches-of-midgard-land-and-expand",
    imgUrl: "./images/slots/Riches-of-Midgard-Land-and-Expand.jpg",
    name: "Riches of Midgard: Land and Expand",
  },
  slots107: {
    redirectUrl: "/casino/ezgne-scruffy-duck",
    imgUrl: "./images/slots/Scruffy-Duck.jpg",
    name: "Scruffy Duck",
  },
  slots108: {
    redirectUrl: "/casino/ezgne-secret-of-the-stones",
    imgUrl: "./images/slots/Secret-of-the-Stones.jpg",
    name: "Secret of the Stones",
  },
  slots109: {
    redirectUrl: "/casino/ezgne-secrets-of-christmas",
    imgUrl: "./images/slots/Secrets-of-Christmas.jpg",
    name: "Secrets of Christmas",
  },
  slots110: {
    redirectUrl: "/casino/ezgne-serengeti-kings",
    imgUrl: "./images/slots/Serengeti-Kings.jpg",
    name: "Serengeti Kings",
  },
  slots111: {
    redirectUrl: "/casino/ezgne-space-wars",
    imgUrl: "./images/slots/Space-Wars.jpg",
    name: "Space Wars",
  },
  slots112: {
    redirectUrl: "/casino/ezgne-victorious",
    imgUrl: "./images/slots/Victorious.jpg",
    name: "Victorious",
  },
  slots113: {
    redirectUrl: "/casino/ezgne-warlords-crystal-of-power",
    imgUrl: "./images/slots/Warlords-Crystal-of-Power.jpg",
    name: "Warlords: Crystal of Power",
  },
  slots114: {
    redirectUrl: "/casino/ezgne-wild-water",
    imgUrl: "./images/slots/Wild-Water.jpg",
    name: "Wild Water",
  },
  slots115: {
    redirectUrl: "/casino/ezgne-wild-worlds",
    imgUrl: "./images/slots/Wild-Worlds.jpg",
    name: "Wild Worlds",
  },
  slots116: {
    redirectUrl: "/casino/ezgrt-4-squad",
    imgUrl: "./images/slots/4-Squad.jpg",
    name: "4 Squad",
  },
  slots117: {
    redirectUrl: "/casino/ezgrt-5-families",
    imgUrl: "./images/slots/5-Families.jpg",
    name: "5 Families",
  },
  slots118: {
    redirectUrl: "/casino/ezgrt-agent-royale",
    imgUrl: "./images/slots/Agent-Royale.jpg",
    name: "Agent Royale",
  },
  slots118: {
    redirectUrl: "/casino/ezgrt-arcade-bomb",
    imgUrl: "./images/slots/Arcade-Bomb.jpg",
    name: "Arcade Bomb",
  },
  slots119: {
    redirectUrl: "/casino/ezgne-secrets-of-atlantis",
    imgUrl: "./images/slots/Atlantis.jpg",
    name: "Atlantis",
  },
  slots120: {
    redirectUrl: "/casino/ezgrt-aztec-spins",
    imgUrl: "./images/slots/Aztec-Spins.jpg",
    name: "Aztec Spins",
  },
  slots121: {
    redirectUrl: "/casino/ezgrt-cinderellas-ball",
    imgUrl: "./images/slots/Cinderella`s-Ball.jpg",
    name: "Cinderella`s Ball",
  },
  slots122: {
    redirectUrl: "/casino/ezgrt-cirque-de-la-fortune",
    imgUrl: "./images/slots/Cirque-de-la-Fortune.jpg",
    name: "Cirque de la Fortune",
  },
  slots123: {
    redirectUrl: "/casino/ezgrt-clash-of-the-beasts",
    imgUrl: "./images/slots/Clash-of-the-Beasts.jpg",
    name: "Clash of the Beasts",
  },
  slots124: {
    redirectUrl: "/casino/ezgrt-crazy-geniee",
    imgUrl: "./images/slots/Crazy-Genie.jpg",
    name: "Crazy Genie",
  },
  slots125: {
    redirectUrl: "/casino/ezgrt-crystal-mirror",
    imgUrl: "./images/slots/Crystal-Mirror.jpg",
    name: "Crystal Mirror",
  },
  slots126: {
    redirectUrl: "/casino/ezgrt-da-vincis-mystery",
    imgUrl: "./images/slots/Da-Vinci`s-Mystery.jpg",
    name: "Da Vinci`s Mystery",
  },
  slots127: {
    redirectUrl: "/casino/ezgrt-devils-number",
    imgUrl: "./images/slots/Devil`s-Number.jpg",
    name: "Devil`s Number",
  },
  slots128: {
    redirectUrl: "/casino/ezgrt-dice-dice-dice",
    imgUrl: "./images/slots/Dice-Dice-Dice.jpg",
    name: "Dice Dice Dice",
  },
  slots129: {
    redirectUrl: "/casino/ezgrt-five-stars-power-reels",
    imgUrl: "./images/slots/Five-Stars-Power-Reels.jpg",
    name: "Five Stars Power Reels",
  },
  slots130: {
    redirectUrl: "/casino/ezgrt-gems-gone-wild-power-reels",
    imgUrl: "./images/slots/Gems-Gone-Wild-Power-Reels.jpg",
    name: "Gems Gone Wild Power Reels",
  },
  slots131: {
    redirectUrl: "/casino/ezgrt-gems-gone-wild",
    imgUrl: "./images/slots/Gems-Gone-Wild.jpg",
    name: "Gems Gone Wild",
  },
  slots132: {
    redirectUrl: "/casino/ezgrt-golden-leprechaun-megaways",
    imgUrl: "./images/slots/Golden-Leprechaun-Megaways.jpg",
    name: "Golden Leprechaun Megaways",
  },
  slots132: {
    redirectUrl: "/casino/ezgrt-hoard-of-poseidon",
    imgUrl: "./images/slots/Hoard-of-Poseidon.jpg",
    name: "Hoard of Poseidon",
  },
  slots133: {
    redirectUrl: "/casino/ezgrt-jewel-scarabs",
    imgUrl: "./images/slots/Jewel-Scarabs.jpg",
    name: "Jewel Scarabs",
  },
  slots134: {
    redirectUrl: "/casino/ezgrt-legend-of-athena",
    imgUrl: "./images/slots/Legend-of-Athena.jpg",
    name: "Legend of Athena",
  },
  slots135: {
    redirectUrl: "/casino/ezgrt-lucky-halloween",
    imgUrl: "./images/slots/Lucky-Halloween.jpg",
    name: "Lucky Halloween",
  },
  slots136: {
    redirectUrl: "/casino/ezgrt-lucky-little-devil",
    imgUrl: "./images/slots/Lucky-Little-Devil.jpg",
    name: "Lucky Little Devil",
  },
  slots137: {
    redirectUrl: "/casino/ezgrt-lucky-valentine",
    imgUrl: "./images/slots/Lucky-Valentine.jpg",
    name: "Lucky Valentine",
  },
  slots138: {
    redirectUrl: "/casino/ezgrt-lucky-wizard",
    imgUrl: "./images/slots/Lucky-Wizard.jpg",
    name: "Lucky Wizard",
  },
  slots139: {
    redirectUrl: "/casino/ezgrt-masquerade",
    imgUrl: "./images/slots/Masquerade.jpg",
    name: "Masquerade",
  },
  slots140: {
    redirectUrl: "/casino/ezgrt-mayan-gods",
    imgUrl: "./images/slots/Mayan-Gods.jpg",
    name: "Mayan Gods",
  },
  slots141: {
    redirectUrl: "/casino/ezgrt-mega-dragon",
    imgUrl: "./images/slots/Mega-Dragon.jpg",
    name: "Mega Dragon",
  },
  slots142: {
    redirectUrl: "/casino/ezgrt-mega-jade",
    imgUrl: "./images/slots/Mega-Jade.jpg",
    name: "Mega Jade",
  },
  slots143: {
    redirectUrl: "/casino/ezgrt-mega-rise",
    imgUrl: "./images/slots/Mega-Rise.jpg",
    name: "Mega Rise",
  },
  slots144: {
    redirectUrl: "/casino/ezgrt-multiplier-riches",
    imgUrl: "./images/slots/Multiplier-Riches.jpg",
    name: "Multiplier Riches",
  },
  slots145: {
    redirectUrl: "/casino/ezgrt-mystery-reels-megaways",
    imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
    name: "Mystery Reels Megaways",
  },
  slots146: {
    redirectUrl: "/casino/ezgrt-ninja-ways",
    imgUrl: "./images/slots/Ninja-Ways.jpg",
    name: "Ninja Ways",
  },
  slots147: {
    redirectUrl: "/casino/ezgrt-path-of-destiny",
    imgUrl: "./images/slots/Path-of-Destiny.jpg",
    name: "Path of Destiny",
  },
  slots148: {
    redirectUrl: "/casino/ezgrt-persian-fortune",
    imgUrl: "./images/slots/Persian-Fortune.jpg",
    name: "Persian Fortune",
  },
  slots149: {
    redirectUrl: "/casino/ezgrt-reel-heist",
    imgUrl: "./images/slots/Reel-Heist.jpg",
    name: "Reel Heist",
  },
  slots150: {
    redirectUrl: "/casino/ezgrt-snow-wild-and-the-7-features",
    imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.jpg",
    name: "Snow Wild And The 7 Features",
  },
  slots151: {
    redirectUrl: "/casino/ezgrt-robin-hoods-wild-forest",
    imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.jpg",
    name: "Robin Hood`s Wild Forest",
  },
  slots152: {
    redirectUrl: "/casino/ezgrt-sylvan-spirits",
    imgUrl: "./images/slots/Sylvan-Spirits.jpg",
    name: "Sylvan Spirits",
  },
  slots153: {
    redirectUrl: "/casino/ezgrt-the-greatest-train-robbery",
    imgUrl: "./images/slots/The-Greatest-Train-Robbery.jpg",
    name: "The Greatest Train Robbery",
  },
  slots154: {
    redirectUrl: "/casino/ezgrt-the-wild-hatter",
    imgUrl: "./images/slots/The-Wild-Hatter.jpg",
    name: "The Wild Hatter",
  },
  slots155: {
    redirectUrl: "/casino/ezgrt-thors-lightning",
    imgUrl: "./images/slots/Thor`s-Lightning.jpg",
    name: "Thor`s Lightning",
  },
  slots156: {
    redirectUrl: "/casino/ezgrt-tiki-fruits",
    imgUrl: "./images/slots/Tiki-Fruits.jpg",
    name: "Tiki Fruits",
  },
  slots157: {
    redirectUrl: "/casino/ezgrt-totem-lightning",
    imgUrl: "./images/slots/Totem-Lightning.jpg",
    name: "Totem Lightning",
  },
  slots158: {
    redirectUrl: "/casino/ezgrt-vault-cracker",
    imgUrl: "./images/slots/Vault-Cracker.jpg",
    name: "Vault Cracker",
  },
  slots159: {
    redirectUrl: "/casino/ezgrt-vault-of-anubis",
    imgUrl: "./images/slots/Vault-of-Anubis.jpg",
    name: "Vault of Anubis",
  },
  slots160: {
    redirectUrl: "/casino/ezgrt-well-of-wishes",
    imgUrl: "./images/slots/Well-of-Wishes.jpg",
    name: "Well of Wishes",
  },
  slots161: {
    redirectUrl: "/casino/ezgrt-wild-cats-multiline",
    imgUrl: "./images/slots/Wild-Cats-Multiline.jpg",
    name: "Wild Cats Multiline",
  },
  slots162: {
    redirectUrl: "/casino/ezgrt-wild-oclock",
    imgUrl: "./images/slots/Wild-O`Clock.jpg",
    name: "Wild O`Clock",
  },
  slots163: {
    redirectUrl: "/casino/ezgrt-wild-wild-chest",
    imgUrl: "./images/slots/Wild-Wild-Chest.jpg",
    name: "Wild Wild Chest",
  },
  slots164: {
    redirectUrl: "/casino/ezgrt-win-escalator",
    imgUrl: "./images/slots/Win-Escalator.jpg",
    name: "Win Escalator",
  },
  slots165: {
    redirectUrl: "/casino/ezgrt-yucatans-mystery",
    imgUrl: "./images/slots/Yucatan`s-Mystery.jpg",
    name: "Yucatan`s Mystery",
  },
};
