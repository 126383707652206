import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/images/logoUpdated.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import mybets from "../assets/images/icons/mybets.png";

import { Container, Col, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";

import ProviderLogo1 from "../assets/images/providers/evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/ezugi.jpg";
import ProviderLogo3 from "../assets/images/providers/spribe.png";
import ProviderLogo4 from "../assets/images/providers/netent.png";
import ProviderLogo5 from "../assets/images/providers/redtiger.png";
import ProviderLogo6 from "../assets/images/providers/Paragmatic.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";
import slotsicon1 from "../assets/images/slots.png";

import CasinoIcon from "../assets/images/icons/casino-white.png";
import LogInIcon from "../assets/images/icons/wallet.png";
import SupportIcon from "../assets/images/icons/support.png";
import MenuIcon from "../assets/images/icons/casino.png";
import Home1 from "../assets/images/icons/home.png";
import WalletIcon1 from "../assets/images/icons/walletwhite.png";
import SportsIcon1 from "../assets/images/icons/sports.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Niceexch - The Best Online Gaming Site In India
          </Accordion.Header>
          <Accordion.Body>
            <p>
              In order to register for this website, the user is required to
              accept the <a href="/termss">General Terms and Conditions.</a>In
              the event the General Terms and Conditions are updated, existing
              users may choose to discontinue using the products and services
              before the said update shall become effective, which is a minimum
              of two weeks after it has been announced.
            </p>
            <p>
              Niceexch is a growing platform in the industry of online{" "}
              <b>casino in India.</b> We strive hard to create a rich gambling
              experience for all and inspire a winning spirit in our player
              community.
            </p>
            <h2>Niceexch | Online Betting in India</h2>
            <p>
              Online Betting in India is no longer just about winning, but about
              experience, enrichment and exposure. Bharat addresses this niche
              and provides a holistic online betting experience. It encompasses
              sports, casino and live games.
            </p>
            <h3>Sports Betting in India</h3>
            <p>
              In India, almost 150 Million users bet online regularly. The
              number is also increasing every day. Among all the online bets,
              sports betting tops the list. In Sports Betting, Cricket is the
              No. 1 Betting option in India.Go through our cricket betting
              platform and start to bet on cricket online. We offer more than 20
              sports for <b>online betting in India.</b> From everyone’s
              favourite cricket to tennis and football to basketball.<b></b>We
              are sure you will find your favourite sport on our list. And when
              you already love the sport, then why not make enough money with it
              using <b> online betting?</b>
            </p>
            <p>
              Make sure to go through this list of some popular sports you can
              bet on:
            </p>
            <ul>
              <li>Cricket</li>
              <li>Soccer</li>
              <li>Basketball</li>
              <li>E-Sports</li>
              <li>Tennis</li>
              <li>Baseball</li>
              <li>Volleyball</li>
              <li>Table Tennis</li>
            </ul>
            <h3>Casino in India</h3>
            <p>
              Broadly, there are two types of casino games. Be it online or
              offline, all casinos operate with these two types.
            </p>
            <ul class="number">
              <li>
                <h4>Skill-Based Casino Games</h4>
                <p>
                  This is a set of games that are primarily based on skills,
                  strategies and calculations. Though we can’t ignore the ‘luck’
                  aspect, how-a-player-decides matters more in these games.
                </p>
              </li>
              <li>
                <h4>Luck-Based Casino Games</h4>
                <p>
                  Luck-based Casino Games are usually more popular and quicker
                  in terms of game duration. Many betting enthusiasts believe
                  that every player must follow certain strategies even in
                  luck-based games.
                </p>
              </li>
            </ul>
            <p>
              You have a chance to play your favourite Casino games anytime
              anywhere. Choose from a wide variety of western and Indian-origin
              games. No need for any other devices, just play from your mobile
              phone! Master these <b>satta game online</b> to win real money and
              become the king of skills with a little bit of luck.Check Out This
              List Of The Interactive Games That We Offer At Bharat:
            </p>
            <ul>
              <li>Blackjack</li>
              <li>Teen Patti</li>
              <li>Andar Bahar</li>
              <li>Dragon Tiger</li>
              <li>War of Bets</li>
              <li>Lightning Dice</li>
              <li>Roulette</li>
              <li>Dream Catcher</li>
              <li>Baccarat</li>
              <li>Bingo Machine</li>
              <li>Golden Egg</li>
              <li>Dead or Alive</li>
              <li>Drive Multiplayer Mayhem</li>
              <li>Fruit Shop</li>
              <li>Rome The Golden Age</li>
              <li>Gonzo’s Quest</li>
              <li>Halloween Jack</li>
              <li>Jumanji</li>
              <li>Space Wars</li>
              <li>Tak Olympus</li>
              <li>Monster Pop</li>
              <li>Back To Venus</li>
              <li>Fruit Zen</li>
              <li>Sugar Pop</li>
              <li>Great Rhino</li>
              <li>S Lions</li>
              <li>3 Kingdoms Battle of Red Cliffs</li>
              <li>3 Genie Wishes</li>
              <li>Mighty Kong</li>
              <li>Ocean Treasures</li>
              <li>Panda’s Fortune</li>
              <li>Tales of Egypt</li>
              <li>Vegas Magic</li>
            </ul>
            <h2>
              {" "}
              What makes Bharat the best site for sports betting and Casino in
              India?
            </h2>
            <p>
              Our unique and open environment is continuously growing with new
              casino games and new users each day. We have an unmatched
              assortment of online sports betting and online casino games in
              varying categories. Our carefully curated sets of games and
              virtual sports are the right fit for everyone, from beginners to
              emerging players and skill masters to leaders. Our team takes
              immense pride in our exceptional 24x7 customer support services,
              interactive gaming environment, and continuous efforts for
              innovation. To reinforce our dedication towards our players, we
              conduct regular analyses to identify potential chances to make
              online betting even more enjoyable. We identify ourselves with
              every gaming enthusiast and betting aficionado.
            </p>
            <h3> Wide Variety of Games</h3>
            <p>
              The games that we offer at Bharat are not limited to Western games
              alone, but also include popular Indian games. We strive hard to
              provide variations for all kinds of betting enthusiasts. When we
              have a variety of options to choose from, we can make better
              decisions.
            </p>
            <h3> 24x7 Customer Live Support</h3>
            <p>
              Increasing interaction and engagement among users directly
              correlates with trust and security. We are constantly in touch
              with the users through a 24x7 chat box. Our experienced customer
              executives ensure that no question is left unanswered and no user
              is left unattended.
            </p>
            <h3> Withdraw anytime</h3>
            <p>
              All betting journeys culminate in withdrawal. If the withdrawal
              process is too long or too complicated, it may ruin the entire
              betting experience. At Bharat, we ensure that our withdrawal
              process is as simple as our registration process.
            </p>
            <h3> Exciting Rewards</h3>
            <p>
              All betting journeys culminate in withdrawal. If the withdrawal
              process is too long or too complicated, it may ruin the entire
              betting experience. At Bharat, we ensure that our withdrawal
              process is as simple as our registration process.
            </p>
            <h3> Blogs, Guides, Tips &amp; Strategies</h3>
            <p>
              Learning is required to master any sport or activity. To
              strengthen your betting skills, we have regularly updated tips,
              strategies, guides and blogs. Both long form and short form
              content are generated to provide insights for all games and
              sports.
            </p>
            <h3> Live Scores &amp; Updates</h3>
            <p>
              An exclusive strip for live scores, both cricket and football, is
              available on all pages. However, a dedicated scorecard is provided
              for all matches separately as well. You can go through a special
              section of key moments in all important matches.
            </p>
            <h3> Best Betting odds</h3>
            <p>
              We offer the most competitive betting odds for all sports and
              games. Your chance of winning is boosted to make betting even more
              enjoyable. Your skillset, coupled with a bit of luck, is all you
              need to make your betting experience rewarding.
            </p>
            <h3> All Payment Methods</h3>
            <p>
              Though we have separate sections for luck-based games and
              skill-based games, our payments are unified. All popular payment
              channels and modes are available on Bharat.
            </p>
            <h3> Betting in Indian Rupees</h3>
            <p>
              Though UPI payments are enabled, it will be even easier if there
              is any option for INR payments. You can make INR transactions on
              Bharat without any hassle.
            </p>
            <h2> How to bet on Bharat?</h2>
            <p>
              Though UPI payments are enabled, it will be even easier if there
              is any option for INR payments. You can make INR transactions on
              Bharat without any hassle.
            </p>
            <div>
              <span>Step 1: Visit Bharat Website & click Sign Up.</span>
              <span>Step 2: Register with your mobile number & Verify.</span>
              <span>Step 3: Deposit the amount of your choice.</span>
              <span>
                Step 4: Claim the Welcome Bonus & start placing your bets.
              </span>
              <span>Step 5: Play. Win. Withdraw. Seamlessly.</span>
            </div>
            <h3> Bharat Login</h3>
            <p>
              Your Bharat Login is safe and secure. Keep the password and
              username safer and never share them with anyone. You can add your
              deposits to your wallet. Your profits will also be credited
              directly there. Whenever you need to withdraw your profits, you
              can choose to have them instantly credited to your bank account.
              It all happens through your login credentials. So, ensure that it
              is kept safe.
            </p>
            <h3> Bharat Betting App</h3>
            <p>
              To make the betting journey simpler and more accessible, we have a
              mobile application as well. You may simply download the app, use
              your credentials and start placing bets. The app version just
              mirrors the website and it is much faster. Our website also allows
              punters to engage in a variety of sports betting and exchanges.
              The thrill of online betting is incomparable and unmatchable to
              any game. The outcomes of these betting exchanges and games
              revolve around your decisions which significantly impact your
              earnings during the play. We suggest playing with a basic strategy
              and advise you not to bet more than what you can afford. Bharat
              always applauds responsible players. Enjoy Great Winnings in
              Casino Games at Bharat!
            </p>
            <h3> Wrapping up before you begin your betting journey</h3>
            <p>
              Everyone has heard of gambling or has been a part of an online
              casino at least once. Some gamble for money, while others enjoy it
              as a source of entertainment &amp; personal challenge. Choose the
              most reliable site, like Bharat, before you place your bets. It is
              also recommended that you pick the games that suit your interests
              and preference. On a lighter note, here are some interesting facts
              about online casino and gambling.The world’s first online casino
              Microgaming was launched in 1994 just 3 years after the birth of
              the internet.There are an equal number of female gamblers just as
              male players.Australia is the gambling capital of the world.Online
              casinos are on 365 days a year.90% of the slot machines are now
              online.The worldwide gambling population stands at 26%.
            </p>
            <p>
              In the industry of gambling in India, there are many mobile apps
              for casino games and sports betting apps and online sports betting
              sites. Bharat, as an online sportsbook for major sports and casino
              site for popular games, ensures a seamless process, excellent
              customer service, spread betting and prop bet options. Begin your
              betting journey now!
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Container>
        <div className="providers_sec d-none">
          <Row>
            <Col md={6} xs={12}>
              <div className="provider-mob-border">
                <div className="heading mb-4">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>
                      Play Your favorite Casino Games with{" "}
                      <span>Top Providers</span>
                    </p>
                  </div>
                </div>

                <div className="providers_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </a>
                  <a href="/casino/pragmatic" className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </a>
                  <a href="/casino/onetouch" className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="">
                <div className="heading payment-heading mb-4 ">
                  <div className="text">
                    <h5>Payment Gateway</h5>
                    <p>
                      Accept Process &amp; disburse{" "}
                      <span>Digital Payments </span>
                      for your bussiness
                    </p>
                  </div>
                </div>

                <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                  <a href="/casino/evolution" className="logo_box">
                    <img src={PaymentLogo1} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/pragmatic"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo2} alt="Provider Logo" />
                  </a>
                  <a href="casino/ezugi" className="logo_box">
                    <img src={PaymentLogo3} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo4} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo5} alt="Provider Logo" />
                  </a>
                  <a href="/casino/supernowa" className="logo_box">
                    <img src={PaymentLogo6} alt="Provider Logo" />
                  </a>
                  <a
                    href="/casino/onetouch"
                    className="logo_box payment-height"
                  >
                    <img src={PaymentLogo7} alt="Provider Logo" />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-none">
          <div className="providers_img">
            <a href="javascript:void(0)">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="javascript:void(0)">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="javascript:void(0)">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="javascript:void(0)">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links">
            <ul>
              <li>
                <a href="/contactuss">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicys ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegames">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplays">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrules">Games Rules</a>
              </li>
              <li>
                <a href="/termss">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us
            </a>
            {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
          </div>

          <div class="d-flex justify-content-center mt-2 d-none">
            <img
              src={PaymentMod}
              loading="lazy"
              style={{ width: "250px" }}
              alt="Payment Otions"
            />
          </div>

          <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
            <img
              src={Betfair}
              loading="lazy"
              class="mb-3  mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="javascript:void(0)">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                class="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {" "}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                  {/* <span className="notification-count">2</span> */}
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.FACEBOOK != ""
                      ? appDetails?.FACEBOOK
                      : "javascript:void(0)"
                  }
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != ""
                      ? appDetails?.INSTATGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-section">
          <div className="footer-right-part">
            <div className="providers_img">
              <a href="javascript:void(0)">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="javascript:void(0)">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="javascript:void(0)">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="javascript:void(0)">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>
          </div>
          <div className="footer-left-part">
            <div className="footer-menu-web">
              <div>
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <a href="/disputeresolutiions">Dispute Resolution</a>
                  </li>

                  <li>
                    <a href="/payouts">Payout</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>SUPPORT</h3>
                <ul>
                  <li>
                    <a href="/responsiblegames">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/selfexclusion">Self Exclusion</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-menu-web">
              <div>
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <a href="/about-us">About US</a>
                  </li>
                  <li>
                    <a href="/contactuss">Contact US</a>
                  </li>

                  {/* <li>
                    <a href="#">Affiliates</a>
                  </li> */}
                </ul>
              </div>
              <div>
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a href="/privacypolicys">Privacy</a>
                  </li>
                  <li>
                    <a href="/termss"> Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copy-right">
          <p>
            Copyright © 2024 <span>Niceexch</span> | All rights reserved.
          </p>
        </div>
      </Container>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
        <ul className="d-flex m-0 p-0 list-unstyled">
          <li>
            <a className="active" href="/sign-in">
              <img src={MenuIcon} />

              {/* <img src={CasinoIcon} /> */}
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a href="/sports/Cricket">
              <img src={SportsIcon1} />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a className="homeactive" href="/">
              <img src={Home1} />

              <span>Home</span>
            </a>
          </li>

          <li>
            <a href="/sign-in">
              <img src={WalletIcon1} />
              <span>Wallet</span>
            </a>
          </li>
          <li>
            <a
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={SupportIcon} />
              <span>Support</span>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
