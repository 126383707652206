import React from "react";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

const Payouts = () => {
  return (
    <div>
      <div>
        <Header />
      </div>

      <div>
        <main className="main footer-content">
          <div className="WithdrawSection">
            <div className="container">
              <h2 class="sectionTitle"> Payout </h2>
              <div className="terms-content">
                <p>● Making Deposits:</p>

                <ul>
                  <li>
                    ➔ You can find your payment method on the "My Account" and
                    "Deposit" pages of the website.
                  </li>
                  <li>
                    ➔ Accepted payment methods are subject to change. Some
                    payment methods may not be available in all countries.
                  </li>
                  <li>
                    ➔ We accept payments in EUR, BRL, USD, cryptocurrency, or
                    any other currency that you can select in the currency
                    dropdown. Payments received in a currency other than the
                    selected currency will be converted to the currency of your
                    account at an exchange rate selected by us.
                  </li>
                  <li>
                    ➔ You and the people associated with you are disregarding
                    the rules, either directly or indirectly.
                  </li>
                </ul>
                <p>● Payouts: </p>
                <ul>
                  <li>
                    ➔ Any winnings in your account will be made available to you
                    when the outcome of the game you have participated in has
                    been determined or when the outcome of the event has been
                    confirmed by us.
                  </li>
                  <li>
                    ➔ If for any reason winnings that do not belong to you are
                    mistakenly credited to your account, the winnings will be
                    deducted from your account.
                  </li>
                  <li>
                    ➔ If you withdraw your winnings before we become aware of
                    this error, you are obliged to report it to us immediately
                    and refund it. If you do not do this, we will take all
                    possible legal actions to collect this claim.
                  </li>
                </ul>
                <p>● Withdrawals: </p>
                <ul>
                  <li>
                    ➔ If you request a withdrawal without paying more than your
                    deposit amount, we may force a transaction of all or part of
                    the previously made deposit amount and/or charge a fee for
                    any requested increase in the withdrawal amount.{" "}
                  </li>
                  <li>
                    ➔ We may deny withdrawal requests if we suspect that funds
                    have been withdrawn for fraudulent or money laundering
                    purposes.
                  </li>
                  <li>
                    ➔ Before accepting withdrawals, we may ask you to present
                    valid identification such as a certified copy of your
                    passport, ID card, or other document as we deem necessary
                    depending on the circumstances.
                  </li>
                  <li>
                    ➔ We are not responsible for any money lost while playing as
                    a result of withdrawals being canceled by you or us.
                  </li>
                  <li>
                    ➔ If you decide to gamble with the funds in your account
                    before the withdrawal is completed, cancellation of the
                    withdrawal (whether by you or by us) will not entitle you to
                    compensation.{" "}
                  </li>
                  <li>
                    ➔ We reserve the right to charge an administrative fee if
                    you make more than 30 approved withdrawal/withdrawal
                    requests in a calendar month. Calculated for each withdrawal
                    after the 30th withdrawal within a month.
                  </li>
                </ul>
                <p>
                  ● Chargebacks: If a payment method provider requests a
                  chargeback for the card or deposit method used by you, we may:{" "}
                </p>
                <ul>
                  <li>➔ We can suspend your account.</li>
                  <li>
                    ➔ We may take any action to which we are legally entitled.
                  </li>
                  <li>
                    ➔ Chargeback notifications may be issued and sent using your
                    registration information.
                  </li>
                  <li>
                    ➔ Chargebacks will use the same payment method you chose
                    when making the deposit. If we are unable to verify your
                    identity and the payment method used to deposit the account
                    in question, we will send you two reminder emails.{" "}
                  </li>
                  <li>
                    ➔ In the event of a chargeback, your account balance may
                    become negative.
                  </li>
                </ul>

                <h4>Bonuses & Payouts</h4>
                <ul>
                  <li>
                    ● Any bonus can only be redeemed once per individual,
                    family, household, address, email address, credit card
                    number, bank account, phone number, computer/device, and/or
                    IP address.{" "}
                  </li>
                  <li>
                    ● The "Welcome" bonus is for a new customer who has
                    registered an account on the website.
                  </li>
                  <li>
                    ● Some bonuses must be manually activated on your account.
                    Unless otherwise stated, you can do this after logging into
                    your account and depositing the amount required to access
                    the bonus. Such bonuses can only be activated if real money
                    is available in your account.{" "}
                  </li>
                  <li>
                    ● Once a bonus is claimed, real money, bonuses, and winnings
                    from them remain as bonuses and cannot be withdrawn or
                    wagered on other products until the wagering requirements
                    are met.{" "}
                  </li>
                  <li>
                    ● Any attempt to withdraw funds or cash out wagers before
                    the bonus wagering requirements have been fully met may void
                    the bonus and winnings (including real money winnings used
                    to claim the bonus). System bets do not count toward the
                    wagering requirements. Only wagers placed and settled during
                    the Promotion Period will count toward the wagering
                    requirements.{" "}
                  </li>
                  <li>
                    ● If you cancel the bonus, you will lose the bonus and any
                    winnings from the bonus.{" "}
                  </li>
                  <li>
                    ● Irregular Play: We may decide at any time to review all
                    records and game transactions and to monitor your bonus
                    usage. If gameplay is suspected to be irregular, we may
                    exclude you from free spins promotions, bonuses, or other
                    forms of promotion and/or withhold deposits and/or winnings
                    and/or close your account. Irregular play is considered
                    bonus abuse and is not permitted on the site.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default Payouts;
