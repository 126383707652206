import React from "react";
import { useSelector } from "react-redux";

import AffiliatesBanner from "../../../../assets/images/banner/affiliatesbanner.gif";

import FoundedIcon from "../../../../assets/images/icons/affiliates/founder.png";
import AffiliatesIcon from "../../../../assets/images/icons/affiliates/affiliate-marketing.png";
import HighWithdrawIcon from "../../../../assets/images/icons/affiliates/wallet.png";
import TotalWithdrawIcon from "../../../../assets/images/icons/affiliates/withdraw.png";

import ProblemSectionImg from "../../../../assets/images/banner/problemaffiliates.png";

import Payment from "../../../../assets/images/icons/affiliates/payments.png";
import Trust from "../../../../assets/images/icons/affiliates/trust.png";

import SolutionSectionImg from "../../../../assets/images/banner/Solution.png";

import OnboardIcon from "../../../../assets/images/icons/affiliates/onboard.png";
import MarkettingIcon from "../../../../assets/images/icons/affiliates/Marketing.png";
import Payoutsimg from "../../../../assets/images/icons/affiliates/payouts.png";

import StaticsImg from "../../../../assets/images/icons/affiliates/Bhartplays.jpg";

import ContactHeading from "../../../../assets/images/icons/affiliates/Contact.jpg";

import PayoutsIcon1 from "../../../../assets/images/icons/affiliates/money.png";
import DashboardIcons from "../../../../assets/images/icons/affiliates/dashboard.png";

const Affiliates = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div>
      <main className="main">
        <div className="AffiliatesBanner">
          <img src={AffiliatesBanner} alt="AffiliatesBanner" />
        </div>

        <div className="WhoweareSection">
          <h3>Who Are We?</h3>

          <div className="Whowearepart">
            <div className="Whowearecontent">
              <p>
                {" "}
                Niceexch earns trust as the top-rated platform for thousands of
                affiliates. Its integrity, transparency, and effective
                strategies make it a beacon of reliability in the competitive
                realm of affiliate marketing.
              </p>
            </div>

            <div className="WhowearecontentSection">
              <ul>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={FoundedIcon} alt="FoundedIcon" />
                    <p>Founded</p>
                    <h4>2016</h4>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={AffiliatesIcon} alt="FoundedIcon" />
                    <p>Affiliates</p>
                    <h4>903</h4>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={HighWithdrawIcon} alt="FoundedIcon" />
                    <p>Highest Withdrawal</p>
                    <h4>₹ 28 LAKH</h4>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={TotalWithdrawIcon} alt="FoundedIcon" />
                    <p>Total Withdrawal</p>
                    <h4>₹ 12 CR</h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="ProblemSection">
          <div className="ProblemLeftSection">
            <img src={ProblemSectionImg} alt="ProblemSectionImg" />
          </div>
          <div className="ProblemRightSection">
            <h3>WHAT’S THE PROBLEM?</h3>

            <div className="problempart">
              <ul>
                <li>
                  <img src={Payment} alt="Payment" />
                  <h4>Delayed Payments</h4>
                  <p>Most Platforms offering Monthly or Quarterly Payouts</p>
                </li>
                <li>
                  <img src={Trust} alt="Payment" />
                  <h4>Blind Trust</h4>
                  <p>
                    Most Platforms don’t offer any proof of actual Revenue
                    Generated
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="ProblemSection">
          <div className="ProblemLeftSection">
            <img src={SolutionSectionImg} alt="ProblemSectionImg" />
          </div>
          <div className="ProblemRightSection">
            <h3>THIS IS OUR SOLUTION!!!</h3>

            <div className="problempart">
              <ul>
                <li>
                  <img src={PayoutsIcon1} alt="Payment" />
                  <h4>MONDAYS ARE PAYDAYS</h4>
                  <p>
                    {" "}
                    1st Time in the Industry Weekly Payout Structure for
                    Affiliates
                  </p>
                </li>
                <li>
                  <img src={DashboardIcons} alt="Payment" />
                  <h4> PRIVATE DASHBOARD</h4>
                  <p>
                    Transparency over Total Revenue Generated & Referral
                    Statistics.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="HowDoesItWorkSection">
          <h3>HOW DOES IT WORK?</h3>

          <div className="howdoesitworkpart">
            <div className="WhowearecontentSection">
              <ul>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={OnboardIcon} alt="FoundedIcon" />
                    <h4>Digital Onboarding</h4>
                    <p>
                      Open an Affiliate account. Get your private links &
                      Private Dashboard.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={MarkettingIcon} alt="FoundedIcon" />
                    <h4>Marketting Support</h4>
                    <p>
                      Utilize your links in Instagram, Telegram, Whatsapp &
                      Facebook Marketing.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={Payoutsimg} alt="FoundedIcon" />
                    <h4> Easy Payouts</h4>
                    <p>
                      Earn Payouts Every Monday Directly in Your “Niceexch”
                      Account.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="StaticsSection">
          <div className="StaticsLeftSection">
            <h3>AFFILIATE STATISTICS</h3>
            <p>
              {" "}
              STEP INTO THE WORLD OF Niceexch, WHERE THE MAJORITY OF AFFILIATES
              EARN A MINIMUM OF 1 LAKH RUPEES WEEKLY. THESE COMPELLING
              STATISTICS REFLECT ITS UNPARALLELED SUCCESS IN THE REALM OF
              AFFILIATE MARKETING
            </p>
          </div>
          <div className="StaticsImg">
            <img src={StaticsImg} alt="StaticsImg" />
          </div>
        </div>

        <div className="AffiliatesNumber">
          <ul>
            <li>
              <span>70%</span>
              <p>Of our affiliates make atleast 1lakh per week.</p>
            </li>
            <li>
              <span>82%</span>
              <p>
                Of our affiliates have their private zones with Exclusive
                Access.
              </p>
            </li>
            <li>
              <span>75%</span>
              <p>Of our affiliates make their 1st Million in a Quarter.</p>
            </li>
          </ul>
        </div>

        <div className="AffiliatesFooter">
          <div class="ContactHeading">
            <img src={ContactHeading} alt="ContactHeading" />
          </div>

          <div className="bharatplayContact">
            <ul>
              <li>
                <h4>WhatsApp Number</h4>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  +1551550-0120
                </a>
              </li>

              <li>
                <h4>Number</h4>
                <a href={`tel://${appDetails?.PHONE}`}>+01242366666</a>
              </li>

              <li>
                <h4>E-mail</h4>
                <a href="mailto:management@Niceexch.com">
                  management@Niceexch.com
                </a>
              </li>

              <li>
                <h4>Site Link</h4>
                <a href="https://Niceexch.com/" target="_blank">
                  www.bhartaplays.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Affiliates;
